export const environment = {
  production: false,
  panel: "king_",
  domain: "allexchbet.com",
  baseUrl: "https://allexchbet.com",
  trafficTag: '',
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  imgPrefix: '/king_client',
  guestEnable: false,
  darkMode: false,
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      pusher: {
          key: '',
          cluster: ''
      },
      themePath: [''],
      guestEnable: false,
      iframeDomainUrl: '',
      signUpActive: false,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      afLoginRedirectUrl: '/home',
      newHomePage: '',
      isCustomBanner: false,
      demoLogin: false,
      tawkScriptSrc: '',
      intercomScript: false
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '40a68fac809117994696',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-master.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: '2022exch.com',
          name: '2022exch',
          dName: '2022exch',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'e6ad3098903050369e47',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-master.css'],
          guestEnable: false,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'mango777.club',
          name: 'mango777',
          dName: 'mango777',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          themePath: ['assets/css/themes-master.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: '2023exch.com',
          name: '2023exch',
          dName: '2023exch',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '961c82bb314d3e5f22b5',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-2023.css'],
          guestEnable: false,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'angelexch247.com',
          name: 'angelexch247',
          dName: 'angelexch247',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-angelexch247.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: 'home-white-label',
          isCustomBanner: false,
          demoLogin: true,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'shiva247exch.com',
          name: 'shiva247exch',
          dName: 'shiva247exch',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-shiva247exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: 'home-white-label',
          isCustomBanner: true,
          demoLogin: true,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'play365online.com',
          name: 'play365online',
          dName: 'play365online',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          themePath: ['assets/css/themes-play365online.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'play365online.club',
          name: 'play365online',
          dName: 'play365online',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-play365online.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'ashirwad99.com',
          name: 'ashirwad99',
          dName: 'ashirwad99',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          themePath: ['assets/css/themes-master.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: '911exch.com',
          name: '911exch',
          dName: '911exch',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          themePath: ['assets/css/themes-911exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: '777books.com',
          name: '777books',
          dName: '777books',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          themePath: ['assets/css/themes-911exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'falconexch.in',
          name: 'falconexch',
          dName: 'falconexch',
          ext: '.in',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '40928c154aa1ed0c1c8d',
              cluster: 'ap2'
          },
          themePath: ['assets/css/themes-911exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'falconexch.club',
          name: 'falconexch',
          dName: 'falconexch',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-911exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'ak247exch.com',
          name: 'ak247exch',
          dName: 'ak247exch',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-shiva247exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: 'home-white-label',
          isCustomBanner: false,
          demoLogin: true,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'bigbull69.bet',
          name: 'bigbull69',
          dName: 'bigbull69',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-shiva247exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: 'home-white-label',
          isCustomBanner: true,
          demoLogin: true,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'freeplay24.com',
          name: 'freeplay24',
          dName: 'freeplay24',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-freeplay24.css'],
          guestEnable: true,
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: 'home-freeplay',
          isCustomBanner: true,
          demoLogin: true,
          // tawkScriptSrc: 'https://embed.tawk.to/64d39bd494cf5d49dc6963f4/1h7d9he0d',
          tawkScriptSrc: '',
          intercomScript: true
      },
      {
          domain: 'gem999.win',
          name: 'gem999',
          dName: 'gem999',
          ext: '.win',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-shiva247exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: 'home-white-label',
          isCustomBanner: true,
          demoLogin: true,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'skybook99.com',
          name: 'skybook99',
          dName: 'skybook99',
          ext: '.club',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-skybook99.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/home',
          newHomePage: '',
          isCustomBanner: false,
          demoLogin: false,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
          domain: 'crystal247.bet',
          name: 'crystal247',
          dName: 'crystal247',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '957d2dd7ae5f87661d90',
              cluster: 'eu'
          },
          themePath: ['assets/css/themes-shiva247exch.css'],
          guestEnable: true,
          iframeDomainUrl: '',
          signUpActive: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: 'home-white-label',
          isCustomBanner: true,
          demoLogin: true,
          tawkScriptSrc: '',
          intercomScript: false
      },
      {
        domain: 'freedomexch.co',
        name: 'freedomexch',
        dName: 'freedomexch',
        ext: '.co',
        theme: 'yellow-body',
        email: '',
        pusher: {
            key: 'c8bbdb36496c73b41567',
            cluster: 'eu'
        },
        themePath: ['assets/css/themes-freedom.css'],
        guestEnable: true,
        iframeDomainUrl: '',
        signUpActive: false,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        afLoginRedirectUrl: '/home',
        newHomePage: '',
        isCustomBanner: false,
        demoLogin: false,
        tawkScriptSrc: '',
        intercomScript: false
    }
  ]
};
